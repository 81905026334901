import React, { useEffect, useState } from "react";
import { Map } from "./Map";
import { GlobalStyle } from "./styles";
import { AddressPicker } from "./AddressPicker";
import { useParams, useNavigate } from "react-router-dom";

interface Props {}

export const App: React.FC<Props> = () => {
  const [currentBuildingNumber, setCurrentBuildingNumber] = useState<number>();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.houseId) setCurrentBuildingNumber(parseInt(params.houseId));
  }, []);

  const onChangeBuildingNumber = (value: number) => {
    navigate(`/${value}`);
    setCurrentBuildingNumber(value);
  };

  return (
    <>
      <GlobalStyle />

      <div className="App">
        <Map currentBuildingNumber={currentBuildingNumber} />
        <AddressPicker
          onChangeBuildingNumber={onChangeBuildingNumber}
          currentBuildingNumber={currentBuildingNumber}
        />
      </div>
    </>
  );
};
