import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MapOf } from "./types";

interface Props {
  currentBuildingNumber?: number;
}

const blinkInterval = 250;

export const Map: React.FC<Props> = ({ currentBuildingNumber }) => {
  const [allBuildingsAddressElMap, setAllBuildingsAddressElMap] =
    useState<MapOf<SVGElement> | null>(null);

  const [currentBuildingEl, setCurrentBuildingEl] = useState<SVGElement | null>(
    null
  );

  const [blinkOn, setBlinkOn] = useState<boolean>(false);

  const highlightCurrentBuilding = () => {
    // currentBuilding && currentBuilding.setAttribute("fill", highlightFill);
    currentBuildingEl && currentBuildingEl.classList.add("highlight");
  };

  // TODO Rename to forgetSelection?
  const resetBuilding = () => {
    // currentBuilding?.setAttribute("fill", defaultFill);
    currentBuildingEl && currentBuildingEl.classList.remove("highlight");
  };

  useEffect(() => {
    const freeholdEls = Array.from<SVGElement>(
      document.querySelectorAll("[data-num]")
    );
    const freeholdAddressElMap = freeholdEls.reduce(
      (addrMap, el) => ({ ...addrMap, [el.dataset.num ?? "1"]: el }),
      {}
    );
    const apartmentsEl =
      document.querySelector<SVGElement>("[data-num-series]");
    const apartmentNumbers = apartmentsEl?.dataset["numSeries"]
      ?.split(",")
      .map((appartmentAddressNum: string) => parseInt(appartmentAddressNum));

    if (apartmentNumbers) {
      const elMap = apartmentNumbers.reduce(
        (addrMap, apartmentNum) => ({
          ...addrMap,
          [apartmentNum]: apartmentsEl,
        }),
        freeholdAddressElMap
      );
      setAllBuildingsAddressElMap(elMap);
    } else {
      console.warn("*** Failed to parse SVG map");
    }
  }, []);

  useEffect(() => {
    if (allBuildingsAddressElMap) {
      const timeoutId = setTimeout(() => {
        setBlinkOn(!blinkOn);
      }, blinkInterval);

      return () => clearInterval(timeoutId);
    }
  }, [allBuildingsAddressElMap, blinkOn]);

  useEffect(() => {
    if (currentBuildingEl) {
      resetBuilding();
    }

    if (allBuildingsAddressElMap && currentBuildingNumber) {
      setCurrentBuildingEl(allBuildingsAddressElMap[currentBuildingNumber]);
    }
  }, [currentBuildingNumber]);

  useEffect(() => {
    if (blinkOn) {
      highlightCurrentBuilding();
    } else {
      if (currentBuildingEl) {
        resetBuilding();
      }
    }
  }, [blinkOn]);

  return (
    <SVGContainer
      dangerouslySetInnerHTML={{
        __html: `
  <svg id="map" enable-background="new -0.467 -0.333 567 603" overflow="visible" version="1.1" viewBox="-0.467 -0.333 567 603">
		<rect transform="matrix(.8838 -.4678 .4678 .8838 -23.143 178.38)" x="333.36" y="122.02" width="28.437" height="27.532" data-num="1" />
		<rect transform="matrix(.8838 -.4678 .4678 .8838 -28.276 168.93)" x="320.68" y="125.93" width="10.644" height="30.939" data-num="2" />
		<rect transform="matrix(.8838 -.4678 .4678 .8838 -32.243 164.59)" x="309.95" y="131.74" width="10.644" height="30.939" data-num="3" />
		<rect transform="matrix(.8838 -.4678 .4678 .8838 -35.914 159.28)" x="297.42" y="136.48" width="10.644" height="30.939" data-num="4" />
		<rect transform="matrix(.8838 -.4678 .4678 .8838 -40.857 154.87)" x="286.07" y="144.23" width="10.644" height="30.939" data-num="5" />
		<rect transform="matrix(.8838 -.4678 .4678 .8838 -44.47 149.83)" x="274.13" y="148.99" width="10.644" height="30.939" data-num="6" />
		<rect transform="matrix(.8838 -.4678 .4678 .8838 -48.225 144.84)" x="262.18" y="154.05" width="10.644" height="30.939" data-num="7" />
		<rect transform="matrix(.8838 -.4678 .4678 .8838 -52.579 139.99)" x="250.24" y="160.39" width="10.644" height="30.939" data-num="8" />
		<polygon class="apartments" points="297.83 108.74 204.31 153.48 190.93 125.49 284.45 80.756" data-num-series="101,102,103,104,105,106,107,108,109,110,101,201,202,203,204,205,206,207,208,209,210" />
		<rect transform="matrix(.8838 -.4678 .4678 .8838 -41.273 187.7)" x="348.42" y="163.18" width="17.704" height="27.532" data-num="43" />
		<rect transform="matrix(.914 -.4056 .4056 .914 -48.422 155.55)" x="337.47" y="176.55" width="10.644" height="30.939" data-num="42" />
		<rect transform="matrix(.914 -.4056 .4056 .914 -51.588 151.19)" x="325.61" y="181.84" width="10.644" height="30.939" data-num="41" />
		<rect transform="matrix(.914 -.4056 .4056 .914 -54.861 147.16)" x="314.47" y="187.55" width="10.644" height="30.939" data-num="40" />
		<rect transform="matrix(.914 -.4056 .4056 .914 -58.027 142.81)" x="302.6" y="192.84" width="10.644" height="30.939" data-num="39" />
		<rect transform="matrix(.914 -.4056 .4056 .914 -62.853 139.33)" x="292" y="202.5" width="10.644" height="30.939" data-num="38" />
		<rect transform="matrix(.3545 -.9351 .9351 .3545 -43.943 415.38)" x="273.56" y="224.05" width="10.644" height="30.94" data-num="37" />
		<rect transform="matrix(.3545 -.9351 .9351 .3545 -58.591 419.15)" x="268.95" y="236.54" width="10.644" height="30.94" data-num="36" />
		<rect transform="matrix(.3545 -.9351 .9351 .3545 -73.247 422.91)" x="264.35" y="249.03" width="10.644" height="30.94" data-num="35" />
		<rect transform="matrix(.3545 -.935 .935 .3545 -87.914 426.64)" x="259.74" y="261.53" width="10.643" height="30.939" data-num="34" />
		<rect transform="matrix(.3545 -.9351 .9351 .3545 -102.56 430.42)" x="255.14" y="274.02" width="10.644" height="30.94" data-num="33" />

		<rect transform="matrix(.3545 -.9351 .9351 .3545 -59.456 355.5)" x="222.44" y="205.34" width="10.644" height="30.94" data-num="15" />
		<rect transform="matrix(.3545 -.9351 .9351 .3545 -74.106 359.27)" x="217.83" y="217.84" width="10.644" height="30.94" data-num="16" />
		<rect transform="matrix(.3545 -.9351 .9351 .3545 -88.762 363.03)" x="213.22" y="230.33" width="10.644" height="30.94" data-num="17" />
		<rect transform="matrix(.3545 -.935 .935 .3545 -103.43 366.77)" x="208.62" y="242.83" width="10.643" height="30.939" data-num="18" />
		<rect transform="matrix(.3545 -.9351 .9351 .3545 -118.08 370.55)" x="204.02" y="255.32" width="10.644" height="30.94" data-num="19" />

		<rect transform="matrix(.3545 -.9351 .9351 .3545 -93.577 288.37)" x="156.75" y="198.1" width="10.644" height="27.724" data-num="11" />
		<rect transform="matrix(.3545 -.9351 .9351 .3545 -108.23 292.13)" x="152.14" y="210.59" width="10.644" height="27.724" data-num="12" />
		<rect transform="matrix(.3545 -.9351 .9351 .3545 -122.89 295.87)" x="147.52" y="223.08" width="10.644" height="27.724" data-num="13" />
		<rect transform="matrix(.3545 -.935 .935 .3545 -137.56 299.6)" x="142.9" y="235.57" width="10.643" height="27.723" data-num="14" />

		<rect transform="matrix(.3545 -.9351 .9351 .3545 -125.4 436.32)" x="248" y="293.52" width="10.644" height="30.94" data-num="32" />
		<rect transform="matrix(.3545 -.9351 .9351 .3545 -140.05 440.1)" x="243.39" y="306.01" width="10.644" height="30.94" data-num="31" />
		<rect transform="matrix(.3545 -.9351 .9351 .3545 -154.71 443.85)" x="238.79" y="318.51" width="10.644" height="30.94" data-num="30" />
		<rect transform="matrix(.3545 -.935 .935 .3545 -169.37 447.58)" x="234.18" y="331" width="10.643" height="30.939" data-num="29" />
		<rect transform="matrix(.3545 -.9351 .9351 .3545 -184.02 451.37)" x="229.58" y="343.5" width="10.644" height="30.94" data-num="28" />

		<rect transform="matrix(.3299 -.944 .944 .3299 -184.13 400.02)" x="184.4" y="314.24" width="10.644" height="30.939" data-num="23" />
		<rect transform="matrix(.3298 -.944 .944 .3298 -169.28 395.78)" x="188.79" y="301.65" width="10.644" height="30.938" data-num="22" />
		<rect transform="matrix(.3298 -.9441 .9441 .3298 -154.44 391.5)" x="193.19" y="289.05" width="10.644" height="30.939" data-num="21" />
		<rect transform="matrix(.3298 -.944 .944 .3298 -139.6 387.18)" x="197.58" y="276.45" width="10.645" height="30.94" data-num="20" />
		<rect transform="matrix(.3297 -.9441 .9441 .3297 -198.95 404.4)" x="180" y="326.84" width="10.645" height="30.941" data-num="24" />
		<rect transform="matrix(.3296 -.9441 .9441 .3296 -213.78 408.74)" x="175.6" y="339.44" width="10.646" height="30.941" data-num="25" />
		<rect transform="matrix(.3298 -.944 .944 .3298 -228.64 412.95)" x="171.2" y="352.04" width="10.643" height="30.939" data-num="26" />
		<rect transform="matrix(.3893 -.9211 .9211 .3893 -205.67 303.76)" x="120.93" y="291.52" width="10.646" height="30.939" data-num="47" />
		<rect transform="matrix(.3891 -.9212 .9212 .3891 -191.17 301.11)" x="126.12" y="279.23" width="10.644" height="30.938" data-num="46" />
		<rect transform="matrix(.3891 -.9212 .9212 .3891 -176.68 298.38)" x="131.31" y="266.93" width="10.644" height="30.94" data-num="45" />
		<rect transform="matrix(.3893 -.9211 .9211 .3893 -162.19 295.6)" x="136.5" y="254.64" width="10.645" height="30.94" data-num="44" />
		<rect transform="matrix(.3891 -.9212 .9212 .3891 -220.16 306.58)" x="115.74" y="303.81" width="10.643" height="30.941" data-num="48" />
		<rect transform="matrix(.389 -.9212 .9212 .389 -234.66 309.34)" x="110.55" y="316.1" width="10.645" height="30.941" data-num="49" />
		<rect transform="matrix(.389 -.9212 .9212 .389 -249.15 312.06)" x="105.36" y="328.4" width="10.646" height="30.94" data-num="50" />
		<rect transform="matrix(.3298 -.9441 .9441 .3298 -243.47 417.25)" x="166.81" y="364.63" width="10.644" height="30.94" data-num="27" />
		<rect transform="matrix(-.0031 -1 1 -.0031 -11.747 349.47)" x="163" y="165.12" width="10.644" height="30.94" data-num="9" />
		<rect transform="matrix(-.003 -1 1 -.003 -24.905 362.98)" x="163.17" y="178.44" width="10.644" height="30.939" data-num="10" />
		<rect transform="matrix(.5203 -.854 .854 .5203 -300.26 258.02)" x="74.223" y="380.82" width="10.645" height="30.94" data-num="54" />
		<rect transform="matrix(.5202 -.854 .854 .5202 -287.22 258.52)" x="81.161" y="369.42" width="10.644" height="30.938" data-num="53" />
		<rect transform="matrix(.5202 -.854 .854 .5202 -274.16 258.99)" x="88.098" y="358.03" width="10.643" height="30.94" data-num="52" />
		<rect transform="matrix(.5203 -.854 .854 .5203 -261.08 259.41)" x="95.036" y="346.63" width="10.645" height="30.94" data-num="51" />
		<rect transform="matrix(.5202 -.8541 .8541 .5202 -313.35 257.63)" x="67.285" y="392.22" width="10.643" height="30.941" data-num="55" />
		<rect transform="matrix(.52 -.8541 .8541 .52 -326.44 257.23)" x="60.347" y="403.61" width="10.646" height="30.942" data-num="56" />
		<rect transform="matrix(.5108 -.8597 .8597 .5108 -384.28 260.14)" x="31.128" y="452.28" width="10.644" height="30.939" data-num="60" />
		<rect transform="matrix(.5107 -.8598 .8598 .5107 -371.13 260.46)" x="37.938" y="440.81" width="10.643" height="30.937" data-num="59" />
		<rect transform="matrix(.5105 -.8599 .8599 .5105 -357.96 260.77)" x="44.746" y="429.34" width="10.645" height="30.941" data-num="58" />
		<rect transform="matrix(.5107 -.8597 .8597 .5107 -344.72 260.91)" x="51.558" y="417.86" width="10.644" height="30.94" data-num="57" />
		<rect transform="matrix(.5105 -.8599 .8599 .5105 -397.56 260.06)" x="24.317" y="463.76" width="10.645" height="30.941" data-num="61" />
		<rect transform="matrix(.5106 -.8598 .8598 .5106 -410.74 259.78)" x="17.507" y="475.23" width="10.646" height="30.939" data-num="62" />
		<rect transform="matrix(.5106 -.8598 .8598 .5106 -423.95 259.56)" x="10.697" y="486.71" width="10.645" height="30.941" data-num="63" />
		<rect transform="matrix(.5108 -.8597 .8597 .5108 -327.82 323.69)" x="115.21" y="434.44" width="10.644" height="30.939" data-num="67" />
		<rect transform="matrix(.5107 -.8598 .8598 .5107 -314.65 324.02)" x="122.02" y="422.97" width="10.643" height="30.937" data-num="68" />
		<rect transform="matrix(.5105 -.8599 .8599 .5105 -301.46 324.34)" x="128.83" y="411.49" width="10.645" height="30.941" data-num="69" />
		<rect transform="matrix(.5107 -.8597 .8597 .5107 -288.24 324.47)" x="135.64" y="400.02" width="10.644" height="30.94" data-num="70" />
		<rect transform="matrix(.5105 -.8599 .8599 .5105 -341.06 323.62)" x="108.4" y="445.91" width="10.645" height="30.941" data-num="66" />
		<rect transform="matrix(.5106 -.8598 .8598 .5106 -354.25 323.35)" x="101.59" y="457.39" width="10.646" height="30.939" data-num="65" />
		<rect transform="matrix(.5106 -.8598 .8598 .5106 -367.46 323.13)" x="94.78" y="468.86" width="10.645" height="30.941" data-num="64" />
			<g fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="2">
				<path d="m393.25 125.24s-0.192 0.279-0.588 0.803"/>
				<path d="m391.43 127.61c-2.498 3.076-7.644 8.885-15.946 15.921-11.736 9.947-93.386 42.712-110.42 62.374-17.591 20.307-69.455 174.03-76.003 195.19" stroke-dasharray="2.0034,2.0034"/>
				<path d="m188.77 402.06c-0.121 0.399-0.213 0.72-0.278 0.956"/>
			</g>
		<path d="m168.96 147.21s38.837 13.389 41.512 20.896 4.055 11.257 24.426 18.611 29.874 16.812 27.631 21.597 4.978-5.298 4.978-5.298 25.686 36.767 42.93 42.782" fill="none" stroke="#ccc" stroke-dasharray="2,2" stroke-miterlimit="10" stroke-width="2"/>
			<g fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="2">
				<path d="m120.31 247.84s0.325 0.132 0.923 0.375"/>
				<path d="m123.07 248.96c10.56 4.292 47.986 19.52 51.578 21.153 9.87 4.489 41.59 15.473 51.222 18.745" stroke-dasharray="1.9872,1.9872"/>
				<path d="m226.81 289.18c0.365 0.123 0.681 0.229 0.943 0.317"/>
			</g>
			<g fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="2">
				<path d="m84.377 339.84s0.293 0.192 0.835 0.543"/>
				<path d="m86.939 341.49c7.91 5.058 32.045 20.14 42.512 22.695" stroke-dasharray="2.0554,2.0554"/>
				<path d="m130.46 364.4c0.349 0.062 0.679 0.106 0.987 0.132"/>
			</g>
		<line x1="423.09" x2="406.53" y1="97.962" y2="71.667" fill="none" stroke="#666" stroke-miterlimit="10" stroke-width="8"/>
			<g fill="none" stroke="#A0A0A0" stroke-miterlimit="10" stroke-width="4">
				<path d="m484.53 4.667s-0.277 2.203-0.824 5.938"/>
				<path d="m481.85 22.376c-2.33 13.824-6.148 33.3-11.321 49.291-22.131 68.404-76.083 135.56-106.79 182.38-12.214 18.623-88.431 130.05-105.82 154.84s-66.774 71.805-105.08 93.295c-33.188 18.618-108.18 72.144-138.68 90.021" stroke-dasharray="11.9198,11.9198"/>
				<path d="m8.96 595.13c-2.249 1.2-4.08 2.065-5.427 2.541"/>
			</g>
		<path d="m493.53 103.61s-1.295-18.004-15-17.942-13-6-27 0-60 26-60 26 8 52.563 0 65.282-16.686 40.399-37.601 45.898c-15.99 4.205-30.967 6.298-43.495 22.955s-35.305 109.57-59.187 131.83c-22.973 21.408-49.781 54.496-122.59 2.473" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
		<path d="m391.53 111.67c-11.547-6.689-28.883-11.06-56.666-3.283-6.295 1.762-116.32 39.909-136.36 88.929-19.908 48.705-46.417 143.65-60.517 167.22-43.627 72.907-68.906 113.7-102.7 164.84" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
		<line x1="558.53" x2="168.96" y1="4.667" y2="597.67" fill="none" stroke="#666" stroke-miterlimit="10" stroke-width="17"/>

		{/* legend */}
		<line x1="326.47" x2="363.47" y1="460.96" y2="460.96" fill="none" stroke="#666" stroke-miterlimit="10" stroke-width="17"/>
		<line x1="326.47" x2="363.47" y1="490.3" y2="490.3" fill="none" stroke="#A0A0A0" stroke-dasharray="12" stroke-miterlimit="10" stroke-width="4"/>
		<line x1="326.47" x2="363.47" y1="519.63" y2="519.63" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
		<line x1="326.47" x2="363.47" y1="548.97" y2="548.97" fill="none" stroke="#ccc" stroke-dasharray="2,2" stroke-miterlimit="10" stroke-width="2"/>
		<text transform="translate(373.47 465.96)" font-family="'ArialMT'" font-size="15">Government Rd</text>
		<text transform="translate(373.47 494.3)" font-family="'ArialMT'" font-size="15">Railroad</text>
		<text transform="translate(373.47 523.63)" font-family="'ArialMT'" font-size="15">Amblepath roadway</text>
		<text transform="translate(373.47 552.97)" font-family="'ArialMT'" font-size="15">Walking path</text>
		<rect x="309.66" y="435.78" width="208.3" height="132.78" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width=".8946"/>
	</svg>
  `,
      }}
    />
  );
};

const SVGContainer = styled.span`
  svg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    rect[data-num],
    .apartments {
      fill: #95c8db;

      &.highlight {
        fill: #ef9500;
      }
    }
  }
`;
