import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { App } from "./App";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path=":houseId" element={<App />} />
      <Route path="*" element={<App />} />
    </Routes>
  </BrowserRouter>, document.getElementById("root")
);
