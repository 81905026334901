import React from "react";
import styled from "styled-components";

interface Props {
  currentBuildingNumber?: number;
  onChangeBuildingNumber: (buildingNum: number) => void;
}

const buildingNumbers = [
  // 1 to 70
  ...Array(70)
    .fill(null)
    .map((x, i) => i + 1),
  ...[
    101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 201, 202, 203, 204, 205,
    206, 207, 208, 209, 210,
  ],
];

export const AddressPicker: React.FC<Props> = ({
  currentBuildingNumber,
  onChangeBuildingNumber,
}) => {
  return (
    <Container>
      <h6>Amblepath Map</h6>
      <select
        defaultValue="label"
        onChange={(event) =>
          onChangeBuildingNumber(parseInt(event.target.value))
        }
        className="form-control"
      >
        <option value="label" disabled>
          Find Address
        </option>
        {buildingNumbers.map((buildingNum) => (
          <option
            value={buildingNum.toString()}
            key={buildingNum.toString()}
            selected={buildingNum === currentBuildingNumber}
          >
            {buildingNum}
          </option>
        ))}
      </select>
      <DriveSlowMsg>
        Children playing on road.
        <br />
        <b>Please</b> drive slowly
        <br />
        <MaxSpeed>Max 20 KM / h</MaxSpeed>
      </DriveSlowMsg>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
`;

const DriveSlowMsg = styled.div`
  margin-top: 20px;
  font-size: 0.8em;
`;

const MaxSpeed = styled.span`
  color: #c00;
`;
